footer {
	padding: 20px 0;

	.section__title{
		text-align: left;
	}

	.footer-main {
		a {
			margin-top: 24px;
			padding: 11px 45px;

			display: inline-block;
			background: #E30613;
			border-radius: 10px;
			text-decoration: none;

			font-size: 20px;
			line-height: 140%;

			color: #ffffff;
		}
	}
	.footer-bottom {
		margin: 0 auto;
		margin-top: 80px;
		max-width: 770px;
		p {
			font-size: 18px;
			line-height: 140%;
			/* or 25px */

			text-align: center;
		}
		p:nth-child(2) {
			margin-top: 38px;
		}
	}
}

@media (max-width: 768px) {
	footer {
		.footer-main {
			a {
				font-size: 14px;
				line-height: 140%;
			}
		}
		.footer-bottom {
			p {
				width: 100% !important;
				font-size: 14px;
				line-height: 140%;
			}
			p:nth-child(2) {
				margin-top: 31px;
			}
		}
	}
}
