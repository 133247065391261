@import "../../variables";

.gifts {
    margin-top: 166px;

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;

        .gift-card {
            border: 1.5px solid transparent;
            transition: all 0.2s linear;

            &:hover{
                box-shadow: -6px 10px 67px 30px rgba(0,0,0,0.1);
                border: 1.5px solid #e30613;
                transform: scale(1.05);
            }
            padding: 28px 40px;
            margin-top: 60px;

            border-radius: 18px;
            border: 1px solid #e5e5e5;
            background: #f7f7f7;
            display: flex;
            flex-direction: column;

            transition: all 0.3s linear;

            // &:hover,

            &__price {
                margin: 25px 0;
                
                font-style: normal;
                font-weight: 800;
                font-size: 66px;

                border-bottom: 3px solid #e30613;
                color: $main-color;

                text-align: center;
                font-family: "Roboto", sans-serif;
                font-size: 66px;
                font-style: normal;
                font-weight: 800;
                line-height: 56px; /* 84.848% */
                letter-spacing: -1.034px;
                // background: linear-gradient(180deg, #e30613 0%, #1a6028 100%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                // background-clip: text;
                // text-fill-color: transparent;
            }
            &__title {
                margin-top: 20px;
                font-family: "Roboto", sans-serif;
                text-align: center;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;

                
                font-feature-settings: "salt" on, "liga" off;

                color: $primary-color;
            }
            &__subtitle {
                width: 80%;
                margin: 0 auto;
                margin-top: 8px;

                
                color: rgba(10, 10, 10, 0.8);
                text-align: center;
                font-family: Roboto;
                font-size: 17px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px; /* 141.176% */
                /* or 162% */
            }

            a {
                flex-grow: 2;
                display: flex;
                justify-content: center;
                align-items: end;
            }
            &__button {
                margin-top: 15px;
                padding: 18px 42px;
                background-color: transparent;
                border: none;
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;

                font-feature-settings: "salt" on, "liga" off;

                color: #e30613;
                cursor: pointer;

                border-radius: 10px;
                border: 1px solid #eae9f2;
                background: #fff;
                transition: all 0.3s linear;

                &:hover {
                    color: #fff;
                    background: #e30613;
                }
                span {
                    font-size: 2em;
                    text-align: center;
                    vertical-align: bottom;
                }
            }

            &--active {

                &:hover{
                    box-shadow: -6px 10px 67px 30px rgba(0,0,0,0.1);
                    border: 1.5px solid #000;
                    transform: scale(1.15);
                }
                transform: scale(1.1);

                cursor: pointer;
                border-radius: 18px;
                background: #e30613;
                color: #fff;
                box-shadow: 0px 30px 30px 0px rgba(60, 0, 98, 0.1);

                .gift-card__price{
                    border-bottom: 3px solid #fff;
                }
                & * {
                    color: #fff;
                }
                button,
                button span {
                    color: $main-color !important;
                }
            }
        }
    }
    .mobile__section {
        picture {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 2600px) {
    .gifts {
        .gift-card {
            min-height: 550px;
        }
    }
}
@media screen and (max-width: 2559px) and (min-width: 1920px) {
    .gifts {
        .cards {
            padding: 0 10%;
            .gift-card {
                padding: 28px 20px;
                min-height: 600px;
            }
        }
    }
}

@media screen and (max-width: 1919px) and (min-width: 1201px) {
    .gifts {
        .cards {
            .gift-card {
                min-height: 600px;
            }
        }
    }
}
@media screen and (max-width: 1200px) and (min-width: 961px)  {
    .gifts {
        margin-top: 50px;

        .cards {
            .gift-card {
                padding: 28px 0;
                min-height: 600px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .gifts {
        margin-top: 50px;

        .cards {
            gap: 0px;
            grid-template-columns: 1fr;
            .gift-card {
                &--active {
                    transform: scale(1);
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .gifts {
        margin-top: 50px;

        .cards {
            gap: 0px;
            grid-template-columns: 1fr;
            .gift-card {
                &__price {
                    font-size: 38px;
                    line-height: 32px;
                }
                &__title {
                    font-size: 24px;
                    line-height: 32px;
                }
                &__subtitle {
                    font-size: 16px;
                    line-height: 26px;
                }
                &__button {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        .mobile__section {
            width: 100%;

            picture {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}
