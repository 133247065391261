@import "../../variables";

.registration-section {
    .section__title {
        color: #0a0a0a;
        font-family: "Montserrat-Bold";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 67.2px; /* 140% */
        text-align: left;
    }

    .section__subtitle {
        color: #0a0a0a;
        font-family: "Montserrat-Regular";
        font-size: 14.08px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.12px; /* 150% */
    }
    &__content {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 50px;
        color: $main-color;
        text-align: center;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
            gap: 100px;
        }
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 150px;
            margin-top: 150px;
        }

        &-item {
            position: relative;
            background: #f8f8f8;

            padding: 120px 30px 60px;

            h3 {
                margin: 0 5%;
                font-family: "Montserrat-Medium";
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 34px;
                text-align: center;
                color: $main-color;
            }
            p {
                margin: 11px 15% 0;
                font-family: "Montserrat-Regular";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 162.5% */
                text-align: center;
                color: $main-color;
            }
        }
        &-item::before {
            position: absolute;
            top: 22%;
            left: 50%;
            z-index: 30;

            display: block;
            // background-color: #E30613;
            transform: translate(-50%, -65%);
            // color: #E30613;
            background: linear-gradient(180deg, rgba(227, 6, 19, 0.5) 0%, rgba(227, 6, 19, 0.5) 100%);

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            font-family: "Montserrat-Bold";
            font-style: normal;
            font-weight: 800;
            font-size: 150px;
            text-align: center;
            z-index: 0;
            opacity: 0.5;
        }
        &-item:nth-child(1)::before {
            content: "1";
        }
        &-item:nth-child(2)::before {
            content: "2";
        }
        &-item:nth-child(3)::before {
            content: "3";
        }
    }
}
