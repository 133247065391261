@import "./variables";

@font-face {
    font-family: "Montserrat-Thin";
    src: url("./assets/Fonts/Montserrat-Thin.ttf") format("truetype"); // font-weight: 100;
}
@font-face {
    font-family: "Montserrat-Regular";
    src: url("./assets/Fonts/Montserrat-Regular.ttf") format("truetype"); // font-weight:400
}
@font-face {
    font-family: "Montserrat-Medium";
    src: url("./assets/Fonts/Montserrat-Medium.ttf") format("truetype"); // font-weight: 500;
}
@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("./assets/Fonts/Montserrat-SemiBold.ttf") format("truetype"); // font-weight: 600;
}
@font-face {
    font-family: "Montserrat-Bold";
    src: url("./assets/Fonts/Montserrat-Bold.ttf") format("truetype"); // font-weight: 700;
}
@font-face {
    font-family: "Montserrat-ExtraBold";
    src: url("./assets/Fonts/Montserrat-ExtraBold.ttf") format("truetype"); // font-weight: 800;
}

*,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
}

#root {
    // background-color: rgba(0, 0, 0, 1);
    overflow-x: hidden;
    // color: #fff;
}
body {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
}
.custom-container {
    max-width: 1440px;
    margin: 0 9.6vw;
}

@media screen and (min-width: 1920px) {
    .custom-container {
        margin: 0 auto;
    }
}

.section {
    &__title {
        color: #19191b;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px; /* 116.667% */

        color: $main-color;
        text-align: center;
    }
    &__subtitle {
        margin-top: 14px;
        color: var(--neutral-700, #374151);
        text-align: center;
        font-feature-settings: "liga" off;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        max-width: 573px;
        margin: 0 auto;
    }
}
.mt {
    margin-top: 100px;
}
// mobile
@media screen and (max-width: 768px) {
    .mt {
        margin-top: 84px;
    }
    .custom-container {
        margin: 0 20px;
    }
    .section-header__titles {
        margin-bottom: 40px;
    }
    .section__title {
        font-size: 36px;
        line-height: 140%;
        width: 80%;
    }
    .section__subtitle {
        width: 90%;
        font-size: 16px;
        line-height: 22.4px;
    }
}
