@import "../../variables";

.hero {
    position: relative;

    background: url("../../assets/hero-bg.png") center;
    background-size: cover;
    width: 100%;
    height: 100%;

    margin-top: 32px;
    padding: 70px 0 130px;

    &__content {
        position: relative;
        z-index: 2;
        max-width: 550px;
        &__picture img {
            transform: translateX(-10%);
        }

        &__text {
            font-family: "Montserrat-Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            // color: #ffffff;
        }
    }
    &__bg {
        position: absolute;
        top: 0px;
        width: 40vw;
        right: 5vw;
        z-index: 1;
        // right: 0;
        transform: translateX(12%);

        img {
            width: 45vw;
        }
    }

    &__button {
        margin-top: 50px;
        padding: 11.5px 40px;

        background-color: transparent;
        border: 1px solid $main-color;
        border-radius: 10px;

        font-family: "Montserrat-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: $main-color;

        transition: background-color 0.3s ease-in-out;
        outline: none;
        cursor: pointer;
    }
    &__button:nth-child(1) {
        margin-right: 24px;
    }
    &__button-active,
    &__button:hover,
    &__button:focus,
    &__button:active {
        border: 1px solid transparent;
        border-radius: 10px;
        background-color: #e30613;
        color: #fff;
    }
}


@media (min-width: 1400px) {

    .hero{
        &__bg {

            img {
                width: 28vw;
            }
			
        }
    }
}

@media (min-width: 2500px) {
    .hero{
        &__bg {

            img {
                width: 20vw;
            }
			
        }
    }
}
@media (max-width: 1200px) {
    .hero {
        margin-top: 120px;
        &__content {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100%;
            &__picture img {
                transform: translateX(10%);
            }

            &__text {
                font-size: 16px;
                line-height: 140%;
                text-align: center;
            }
        }
        &__button a,
        &__button {
            font-size: 14px;
            line-height: 140%;
        }
        &__button:nth-child(1) {
            margin-right: 100px;
        }
        &__bg {
			display: none;
            // margin-top: 10px;
            // top: 100%;
            // width: 100%;
            // &-picture {
            //     width: 100%;

            //     img {
            //         width: 100%;
            //         height: auto;
            //         transform: translateX(-10%);
            //     }
            // }
        }
    }
}

@media (max-width: 768px) {
    .hero {
        margin-top: 120px;

        &__buttons {
            display: flex;
            gap: 10px;
            justify-content: center;
        }
        &__content {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 100%;
            &__picture img {
                transform: translateX(0);
            }
            &__text {
                font-size: 16px;
                line-height: 140%;
                text-align: center;
            }
        }
        &__button a,
        &__button {
            font-size: 14px;
            line-height: 140%;
        }
        &__button:nth-child(1) {
            margin-right: 0;
        }
        &__bg {
            margin-top: 100px;
            top: 100%;
            width: 100%;
            &-picture {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                    transform: translateX(-10%);
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .hero {
		margin-top: 40px;
        &__buttons {
            display: flex;
            // gap: 10px;
            justify-content: center;
            flex-direction: column;
			button{
				margin-top: 20px;
			}
        }
    }
}
